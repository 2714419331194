import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const storeFamilyDayRecords = (payload) => {
  return ApiService.post("family-day-records", payload);
};
export default {
  storeFamilyDayRecords,
};
