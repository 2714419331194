<template>
  <div>
    <!-- begin::Card -->
    <div class="card card-custom gutter-b">
      <div class="card-header flex-nowrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">
            Día de la familia 2024
            <span class="d-block text-muted pt-2 font-size-sm"
              >Puede registrar la asistencia del estudiante al evento "Día de la
              familia" junto con el número de acompañantes y el número de
              ticket.</span
            >
          </h3>
        </div>
      </div>
      <div class="card-body">
        <!-- begin::Search Form -->
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              :label="
                areStudentsLoading
                  ? 'Cargando estudiantes...'
                  : 'Código o nombre del estudiante'
              "
              :loading="areStudentsLoading"
              :disabled="
                areStudentsLoading || !students?.data.length || !onlyOneStudent
              "
              :filter="customFilter"
              rounded
              filled
              hide-details
              open-on-clear
              clearable
              :items="students?.data"
              item-text="code"
              item-value="code"
              return-object
              v-model="selectedStudent"
              @change="get(selectedStudent)"
              @click="wipeCardDetails()"
              @click:clear="wipeCardDetails()"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip color="primary" small v-if="index === 0">
                  <span>{{ item.first_name }} {{ item.last_name }}</span>
                </v-chip>
              </template>
              <!-- end::selected student item -->
              <template v-slot:item="{ item, index }">
                <v-container>
                  <v-row>
                    <v-col class="student-divider mt-3 mb-3">
                      <v-row>
                        <!-- student photo -->
                        <!-- -------------------------- -->
                        <v-col
                          cols="11"
                          sm="2"
                          lg="1"
                          class="ml-2 d-flex flex-column justify-center justify-sm-start align-center justify-lg-end pb-1"
                        >
                          <v-avatar size="60">
                            <img
                              :src="`${item.photo}`"
                              :alt="`${item.full_name}`"
                            />
                          </v-avatar>
                          <v-chip
                            x-small
                            class="ma-2"
                            :color="`${
                              item.status == 'Activo'
                                ? 'success'
                                : 'red darken-1'
                            }`"
                            outlined
                          >
                            <span class="font-weight-bold">{{
                              item.status
                            }}</span>
                          </v-chip>
                        </v-col>
                        <!-- -------------------------- -->
                        <v-col
                          cols="11"
                          sm="3"
                          md="2"
                          class="ml-2 d-flex justify-center align-center py-0"
                        >
                          <span class="font-weight-bold text-h6 text-center">
                            {{ item.code }}
                          </span>
                        </v-col>
                        <!-- -------------------------- -->
                        <v-col
                          cols="11"
                          sm="6"
                          md="4"
                          lg="3"
                          class="ml-2 d-flex justify-center align-center pt-0 pb-1"
                        >
                          <span
                            class="text-uppercase font-weight-medium text-center"
                          >
                            {{ item.first_name }} {{ item.last_name }}
                          </span>
                        </v-col>
                        <!-- -------------------------- -->
                        <v-col
                          cols="11"
                          sm="5"
                          md="3"
                          class="ml-2 d-flex justify-center align-center pt-1 pb-1 pb-sm-4 text-center"
                        >
                          <strong class="ml-1">{{ item.grade }}</strong> -
                          <strong class="ml-1">{{ item.section_group }}</strong>
                        </v-col>
                        <!-- -------------------------- -->
                        <v-col
                          cols="11"
                          sm="6"
                          md="12"
                          lg="2"
                          class="ml-2 d-flex justify-center justify-sm-start align-center justify-lg-end pb-4 pt-0 text-center"
                        >
                          <strong class="ml-1">{{
                            item.technical_group
                          }}</strong>
                        </v-col>
                        <!-- -------------------------- -->
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <!-- end::Search Form -->
        <!--begin: Body Card-->
        <v-scroll-x-transition :hide-on-leave="true" :leave-absolute="true">
          <v-row v-if="isCardVisible">
            <!-- INFORMACIÓN -->
            <v-col>
              <div
                class="rounded-lg d-flex flex-column align-stretch justify-center border"
              >
                <div
                  class="px-4 pb-0 my-5 d-flex flex-column justify-center align-center"
                >
                  <v-img
                    width="140"
                    :src="`${selectedStudent.photo}`"
                    :lazy-src="`${selectedStudent.photo}`"
                    aspect-ratio="0.70"
                    class="grey lighten-2 rounded-lg mb-2"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>

                  <!-- text -->
                  <div class="mx-auto mt-md-2 mb-6 mb-md-0">
                    <p
                      class="text-body-1 blue-grey--text text--lighten-1 ma-0 text-center my-1"
                    >
                      <span class="text-body-1 font-weight-bold">Código: </span>
                      {{ selectedStudent.code }}
                    </p>
                    <p class="ma-0 text-body-1 text-center my-1">
                      <span class="text-body-1 font-weight-bold">Nombres: </span
                      >{{ selectedStudent.first_name }} <br />
                    </p>
                    <p class="ma-0 text-body-1 text-center my-1">
                      <span class="text-body-1 font-weight-bold"
                        >Apellidos: </span
                      >{{ selectedStudent.last_name }}
                    </p>
                    <p class="ma-0 text-body-1 text-center my-1">
                      <span class="text-body-1 font-weight-bold">Grado: </span
                      >{{ selectedStudent.grade }}
                      {{ selectedStudent.section }}
                    </p>
                  </div>
                </div>
              </div>
            </v-col>

            <!-- REGISTRO DE DATOS -->
            <v-col cols="12" md="7" lg="8" xl="9">
              <div
                class="rounded-lg d-flex flex-column align-stretch justify-center border"
              >
                <div class="px-4 pb-0 mt-5 d-flex flex-column">
                  <v-col cols="12" md="12" class="ma-0 pa-0">
                    <v-list>
                      <v-list-item-group multiple v-model="attendance">
                        <v-list-item
                          active-class="blue--text text--accent-4 mb-3"
                          :value="`asistió`"
                        >
                          <template v-slot:default="{ active }">
                            <v-list-item-content>
                              <v-list-item-title
                                ><span class="font-weight-bold"
                                  >Asistencia al evento</span
                                >
                              </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-checkbox
                                :input-value="active"
                                color="blue accent-4"
                              ></v-checkbox>
                            </v-list-item-action>
                          </template>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-col>
                  <v-col cols="12" md="12" class="ma-0 pa-0">
                    <form-group name="ticket" :validator="$v.formData.ticket">
                      <v-text-field
                        slot-scope="{ attrs }"
                        v-bind="attrs"
                        type="number"
                        label="Número correlativo de ticket"
                        v-model="formData.ticket"
                        class="ma-0 pa-0"
                        filled
                        rounded
                        dense
                        :disabled="!attendance[0]"
                      />
                    </form-group>
                  </v-col>
                  <v-col cols="12" md="12" class="ma-0 pa-0">
                    <v-container class="my-0 py-0">
                      <p class="ma-0 pa-0 text-subtitle-2">Acompañantes</p>
                      <v-divider></v-divider>
                    </v-container>
                    <v-list>
                      <v-list-item-group
                        multiple
                        v-model="parentsAttendance"
                        class="mb-2"
                      >
                        <v-list-item
                          active-class="blue--text text--accent-4 mb-4"
                          :value="`father`"
                          :disabled="!attendance[0]"
                        >
                          <template v-slot:default="{ active }">
                            <v-list-item-content>
                              <v-list-item-title>Papá</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-checkbox
                                :input-value="active"
                                color="blue accent-4"
                              ></v-checkbox>
                            </v-list-item-action>
                          </template>
                        </v-list-item>
                        <v-list-item
                          active-class="blue--text text--accent-4"
                          :value="`mother`"
                          :disabled="!attendance[0]"
                        >
                          <template v-slot:default="{ active }">
                            <v-list-item-content>
                              <v-list-item-title>Mamá</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-checkbox
                                :input-value="active"
                                color="blue accent-4"
                              ></v-checkbox>
                            </v-list-item-action>
                          </template>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-col>
                  <v-col cols="12" md="12" class="ma-0 pa-0">
                    <form-group
                      name="número hermanos"
                      :validator="$v.formData.siblings"
                    >
                      <v-text-field
                        slot-scope="{ attrs }"
                        v-bind="attrs"
                        label="Número de hermanos"
                        v-model="formData.siblings"
                        type="number"
                        class="ma-0 pa-0"
                        filled
                        rounded
                        dense
                        :disabled="!attendance[0]"
                      />
                    </form-group>
                  </v-col>
                  <v-col cols="12" md="12" class="ma-0 pa-0">
                    <form-group
                      name="número acompañantes"
                      :validator="$v.formData.others"
                    >
                      <v-text-field
                        slot-scope="{ attrs }"
                        v-bind="attrs"
                        label="Número de otros acompañantes"
                        v-model="formData.others"
                        type="number"
                        class="ma-0 pa-0"
                        filled
                        rounded
                        dense
                        :disabled="!attendance[0]"
                      />
                    </form-group>
                  </v-col>

                  <v-btn
                    depressed
                    color="light-green lighten-4"
                    @click="submit"
                    large
                    class="mb-5 rounded"
                    :loading="isLoading"
                    :disabled="!attendance[0]"
                  >
                    <span class="green--text font-weight-bold"
                      >Registrar asistencia</span
                    >

                    <v-icon right color="green">
                      mdi-check-circle-outline
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-scroll-x-transition>
        <v-scroll-x-transition :hide-on-leave="true" :leave-absolute="true">
          <v-row class="my-15">
            <v-col v-if="!isCardVisible" cols="12">
              <v-col class="mx-auto d-flex flex-column" cols="12" sm="10">
                <div class="mx-auto mb-4 pa-8 rounded-circle red lighten-4">
                  <v-icon large color="red">mdi-account-group-outline</v-icon>
                </div>
                <div>
                  <p class="text-center font-weight-bold text-h6 mb-1">
                    Seleccione un estudiante
                  </p>
                  <p class="text-center font-weight-medium text-body-1">
                    Puede buscar un estudiante mediante el código de carnet o el
                    nombre, <br class="d-none d-md-inline" />
                    así podrá acceder al registro de asistencia del estudiante.
                  </p>
                </div>
              </v-col>
            </v-col>
          </v-row>
        </v-scroll-x-transition>

        <!-- end: Body Card-->
      </div>
    </div>
    <!-- end::Card -->

    <!-- begin::Modals -->
  </div>
</template>

<script>
import ChangeStatusBaseModal from "@/components/modals/ChangeStatusBaseModal.vue";
import studentRepository from "@/repositories/studentRepository";
import eventsRepository from "@/repositories/eventsRepository";
import { required, minValue, maxValue } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";

export default {
  name: "events",
  title: "Eventos | GE ITR",
  components: {
    ChangeStatusBaseModal,
  },
  data() {
    return {
      isLoading: false,
      attendance: [],
      parentsAttendance: [],
      onlyOneStudent: true,
      areStudentsLoading: false,
      selectedStudent: {},
      students: {
        data: [],
        search: "",
        isLoading: false,
        filters: {},
      },
      formData: {},
      isCardVisible: false,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Eventos", route: "events" },
    ]);
    this.getStudents();
    this.$store.dispatch(GET_CURRENT_PAGE_ACTIONS, "Tipos de duración");
  },
  methods: {
    getStudents() {
      this.areStudentsLoading = true;
      studentRepository
        .getAllWithAcademicInformationIncludingDisabled()
        .then(({ data }) => {
          this.students.data = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.areStudentsLoading = false;
        });
    },
    customFilter(item, queryText, itemText) {
      // console.log(item, queryText, itemText);
      const first_name = item.first_name.toLowerCase();
      const last_name = item.last_name.toLowerCase();
      const code = `${item.code}`;
      const searchText = queryText.toLowerCase();

      return (
        first_name.indexOf(searchText) > -1 ||
        code.indexOf(searchText) > -1 ||
        last_name.indexOf(searchText) > -1
      );
    },
    get(student) {
      if (student != null) {
        //request functions
        this.isCardVisible = true;
        return;
      }
      this.isCardVisible = false;
    },
    wipeCardDetails() {
      // this will trigger the function that cleans the props of the component
      this.isCardVisible = false;
      this.selectedStudent = {};
      this.formData = {};
      this.parentsAttendance = [];
      this.attendance = [];
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.fireToast({
          icon: "error",
          title: "Asegurate de llenar el formulario correctamente",
        });
        return;
      }
      this.save();
    },
    save() {
      const attended = !!this.attendance[0];
      const fatherAttendance = !!this.parentsAttendance[0];
      const motherAttendance = !!this.parentsAttendance[1];

      const formattedData = {
        uuid: this.selectedStudent.uuid,
        has_attended: attended,
        ticket_number: this.formData.ticket,
        father_attendance: fatherAttendance,
        mother_attendance: motherAttendance,
        amount_of_siblings: !!this.formData.siblings
          ? this.formData.siblings
          : 0,
        amount_of_other_relatives: !!this.formData.others
          ? this.formData.others
          : 0,
        user_id: this.currentUserPersonalInfo.id_user,
      };

      Swal.fire({
        title: "¿Desea guardar el registro?",
        text: "Esta acción no se puede revertir.",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, registrar",
      }).then((result) => {
        if (result.isConfirmed) {
          this.storeFamilyDayRecord(formattedData);
        }
      });
    },
    storeFamilyDayRecord(payload) {
      this.isLoading = true;
      eventsRepository
        .storeFamilyDayRecords(payload)
        .then(({ data }) => {
          if (data.status) {
            this.sweetAlertResponse({
              status: data.status,
              message: data.message,
            });
          } else {
            this.sweetAlertResponse({
              status: false,
              message: data.message,
            });
          }
        })
        .catch((error) => {
          if (error.response) {
            if (!!error.response.data.errors.ticket_number[0]) {
              this.sweetAlertResponse({
                status: false,
                message: error.response.data.errors.ticket_number[0],
              });
            } else {
              this.fireToast({
                icon: "error",
                title: "No ha sido posible crear el registro 🙁",
              });
            }
          }
        })
        .finally(() => {
          this.isLoading = false;
          this.wipeCardDetails();
          this.$v.$reset();
        });
    },
  },
  computed: {
    ...mapGetters(["currentPageActions", "currentUserPersonalInfo"]),
  },
  beforeDestroy() {
    this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
  },
  validations: {
    formData: {
      ticket: {
        required,
      },
      siblings: {
        
        minValue: minValue(0),
        maxValue: maxValue(20),
      },
      others: {
        
        minValue: minValue(0),
        maxValue: maxValue(20),
      },
    },
  },
};
</script>
<style>
.border {
  border: 1px solid black;
}
</style>
